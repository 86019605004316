<template>
  <!-- 分享页面 -->
  <div class="contentbox">
    <div class="typebox">
      <div
        class="typeitem point"
        :class="{ actype: actypeindex === item.id }"
        @click="typechange(item.id)"
        v-for="item in searchList"
        :key="item.id">
        {{ item.title }}
      </div>
    </div>
    <div class="center">
      <div class="infobox" v-if="actypeindex == 2">
        <div class="infobox" v-if="this.user.anchor != 1">
          <div class="infoitem">
            <div class="infotext">
              <p class="text">我的推广人数</p>
              <p class="numbox">{{ share_user_count }}</p>
            </div>
          </div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">
                下级充值奖励
                <span class="numbox" style="vertical-align: middle">3%</span>
              </p>
              <p
                class="numbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.2rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ share_money }}
              </p>
            </div>
          </div>
        </div>
        <div class="anchorbox" v-else>
          <div class="anitem">
            <div class="aninfotext">
              <p class="antext">累计推广人数</p>
              <p class="annumbox">{{ userdata?.share_num }}</p>
            </div>
          </div>
          <div class="anitem">
            <div class="aninfotext">
              <p class="antext">累计充值人数</p>
              <p class="annumbox">{{ userdata?.change_num }}</p>
            </div>
          </div>
          <div class="anitem">
            <div class="aninfotext">
              <p class="antext">累计充值金额</p>
              <p
                class="annumbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.2rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ userdata?.change_money }}
              </p>
            </div>
          </div>
          <div class="anitem">
            <div class="aninfotext">
              <p class="antext">累计领取佣金</p>
              <p
                class="annumbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.2rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ userdata?.receive_money }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="actypeindex == 1">
        <div class="title">推广链接</div>
        <div class="cord-box">
          <div class="info-box">
            <span class="span">{{ Url }}</span>
            <div @click="textCopy(Url)" class="btn point">复制</div>
          </div>
        </div>
        <div class="title">推广码</div>
        <div class="cord-box">
          <div class="info-box">
            <span class="span">{{ UrlCode }}</span>
            <div @click="textCopy(UrlCode)" class="btn point">复制</div>
          </div>
        </div>
      </div>

      <div v-if="actypeindex == 1">
        <div class="title">奖励说明</div>
        <div class="tipstext" v-if="this.user.anchor == 1">
          用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 =
          下级充值T币额度的3%（奖励即充即送！）
        </div>
        <div class="tipstext" v-else>
          用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 =
          下级充值T币额度的3%（奖励即充即送！）
        </div>
      </div>
      <div v-if="actypeindex == 2">
        <!-- <div class="title">近期奖励</div> -->
        <!-- 用户 -->
        <div v-if="this.user.anchor != 1">
          <NewTable
            :tableRecordList="tableRecordList5"
            :tableData="tableData"
            @pageChange="RecentChange">
            <template slot-scope="item">
              <div v-if="item.item == 'price'">
                <div class="pricebox">
                  <img
                    style="width: 0.2rem; margin-right: 0.1rem"
                    src="../../assets/images/about/user/huobi.png"
                    alt="" />
                  {{ item.data.price }}
                </div>
              </div>
            </template>
          </NewTable>
        </div>
        <!-- 主播 -->
        <div v-else>
          <NewTable
            :tableRecordList="tableRecordList7"
            :tableData="downData"
            @pageChange="downChange">
            <template slot-scope="item">
              <div v-if="item.item == 'status'">
                <div class="statebox">
                  <div class="statetext" v-show="item.data.status == 3">
                    已领取
                  </div>
                  <div class="statetext2" v-show="item.data.status == 2">
                    未领取
                  </div>
                  <div class="statetext1" v-show="item.data.status == 1">
                    未结算
                  </div>
                </div>
              </div>
              <div v-if="item.item == 'change_money'">
                <div class="pricebox">
                  <img
                    style="width: 0.2rem; margin-right: 0.1rem"
                    src="../../assets/images/about/user/huobi.png"
                    alt="" />
                  {{ item.data.change_money }}
                </div>
              </div>
              <div v-if="item.item == 'commission_rate'">
                <div class="pricebox">{{ item.data.commission_rate }}%</div>
              </div>
              <div v-if="item.item == 'commission_money'">
                <div class="pricebox">
                  <img
                    style="width: 0.2rem; margin-right: 0.1rem"
                    src="../../assets/images/about/user/huobi.png"
                    alt="" />
                  {{ item.data.commission_money }}
                </div>
              </div>
            </template>
          </NewTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NewTable from "./components/NewTable.vue";
import { tableRecordList5, tableRecordList7 } from "./index.js";
import { getawards_recordsApi, getCommissionApi } from "@/network/api.js";
export default {
  components: {
    NewTable,
  },
  data() {
    return {
      actypeindex: 1,
      tableRecordList5,
      tableRecordList7,
      tableData: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      downData: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      userdata: {},
    };
  },
  created() {
    this.getuserdata();
    this.getAnchordata();
  },
  methods: {
    getuserdata() {
      let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.userdata = data.user_data;
    },
    // 获取主播分享数据
    getAnchordata(page) {
      let params = {
        page,
      };
      getCommissionApi(params).then((res) => {
        let data = res.data.data.data;
        this.downData = {
          total: res.data.data.total,
          pageSize: res.data.data.per_page,
          list: data,
        };
        // this.Anchordata =[]
      });
    },
    //主播分页
    downChange(v) {
      this.getAnchordata(v.page);
    },
    // 近期奖励分页
    RecentChange(v) {
      this.getdata(v.page);
    },
    // 近期奖励数据获取
    getdata(num) {
      getawards_recordsApi(num).then((res) => {
        let data = res.data.data.data;
        this.tableData = {
          total: res.data.data.total,
          pageSize: res.data.data.per_page,
          list: data.map((item) => {
            return {
              time: item.created_at,
              name: item.next_user.name,
              charge_bean: item.charge_bean,
              price: item.bean,
            };
          }),
        };
      });
    },
    typechange(v) {
      this.actypeindex = v;
      if (v == 2) {
        if (this.user.anchor == 0) {
          this.getdata(1);
        } else {
          this.getuserdata();
        }
      }
    },
    textCopy(t) {
      // 如果当前浏览器版本不兼容navigator.clipboard
      if (!navigator.clipboard) {
        var ele = document.createElement("input");
        ele.value = t;
        document.body.appendChild(ele);
        ele.select();
        document.execCommand("copy");
        document.body.removeChild(ele);

        if (document.execCommand("copy")) {
          this.$message.success("复制成功！");
        } else {
          this.$message.success("复制失败！");
        }
      } else {
        navigator.clipboard
          .writeText(t)
          .then(() => {
            this.$message.success("复制成功！");
          })
          .catch(() => {
            this.$message.success("复制失败！");
          });
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    searchList() {
      let array = [
        {
          title: "奖励说明",
          id: 1,
        },
        {
          title: "近期奖励",
          id: 2,
        },
      ];
      // if (this.user.anchor == 1) {
      //   array = [
      //     {
      //       title: "奖励说明",
      //       id: 1,
      //     },
      //     {
      //       title: "近期奖励",
      //       id: 2,
      //     },
      //     {
      //       title: "下级详情",
      //       id: 3,
      //     },
      //   ];
      // }
      return array;
    },
    Url() {
      return `${location.origin}/Home?IvtCode=${this.user.invite_code}`;
    },
    UrlCode() {
      return `${this.user.invite_code}`;
    },
    share_money() {
      return `${this.user.promotion_total}`;
    },
    share_user_count() {
      return `${this.user.share_user_count}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .typebox {
    width: 100%;
    display: flex;
    .typeitem {
      width: 1.2rem;
      padding: 0.32rem 0 0.08rem 0;
      font-size: 0.18rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      line-height: 0.21rem;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .center {
    width: 10.42rem;
    margin: 0 auto;
    .infobox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.18rem;
      width: 10.42rem;
      .infoitem {
        width: 5.14rem;
        height: 1.66rem;
        background: #101022;
        border: 0.01rem solid #201e43;
        border-radius: 0.06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .infotext {
          text-align: center;
          .text {
            margin-bottom: 0.2rem;
          }
        }
        .numbox {
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
    }
    .anchorbox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.18rem;
      width: 10.42rem;
      .anitem {
        width: 2.5rem;
        height: 1.66rem;
        background: #101022;
        border-radius: 0.04rem;
        border: 0.02rem solid #302d65;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .aninfotext {
          text-align: center;
          .antext {
            margin-bottom: 0.2rem;
            color: #ffffff !important;
            font-weight: 400;
          }
        }
        .annumbox {
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
    }
    .title {
      margin: 0.3rem 0 0.18rem 0;
    }
    .cord-box {
      width: 100%;
      .info-box {
        display: flex;
        .span {
          width: 9.33rem;
          height: 0.37rem;
          background: #08080f;
          border: 0.01rem solid #2f2f5e;
          line-height: 0.37rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8080b5;
          padding-left: 0.18rem;
        }
        .btn {
          width: 0.95rem;
          height: 0.37rem;
          background: linear-gradient(90deg, #242448 0%, #42426d 100%);
          border: 0.01rem solid #8686db;
          text-align: center;
          line-height: 0.37rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 0.14rem;
        }
      }
    }
    .tipstext {
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #8080b5;
    }
  }
}
.statebox {
  font-weight: 400;
  font-size: 0.14rem;
  color: #cccccc;
  .statetext2 {
    color: #49d3ff;
  }
  .statetext1 {
    color: #ff3c48;
  }
}
.pricebox {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
