<template>
  <div class="contentbox">
    <div class="typebox">
      <div
        class="typeitem point"
        :class="{ 'actype': actypeindex === item.id }"
        @click="typechange(item.id)"
        v-for="item in typedata"
        :key="item.id">
        {{ item.title }}
      </div>
    </div>
    <div class="spcontent">
      <div class="contentbox1" v-if="actypeindex === 1">
        <div class="listcenter">
          <div
            class="listitem"
            v-for="(item, index) in performanceList"
            :key="index">
            <div class="lbox">
              <div class="timebox">{{ item.created_at }}</div>
              <div class="imgbox">
                <img
                  v-if="item.cover"
                  style="width: 100%"
                  :src="item.cover"
                  alt="" />
              </div>
            </div>
            <div class="rbox">
              <div class="boxname">{{ item.box_name }}</div>
              <div class="titlename">{{ item.name }}</div>
              <div class="pricebox"></div>
            </div>
            <div class="iconbox">
              <img
                v-show="index == 0"
                src="../../assets/images/NewUser/pc-newuser-topicon1.png"
                alt="" />
              <img
                v-show="index == 1"
                src="../../assets/images/NewUser/pc-newuser-topicon2.png"
                alt="" />
              <img
                v-show="index == 2"
                src="../../assets/images/NewUser/pc-newuser-topicon3.png"
                alt="" />
              <img
                v-show="index == 3"
                src="../../assets/images/NewUser/pc-newuser-topicon4.png"
                alt="" />
              <img
                v-show="index == 4"
                src="../../assets/images/NewUser/pc-newuser-topicon5.png"
                alt="" />
              <img
                v-show="index == 5"
                src="../../assets/images/NewUser/pc-newuser-topicon6.png"
                alt="" />
              <img
                v-show="index == 6"
                src="../../assets/images/NewUser/pc-newuser-topicon7.png"
                alt="" />
              <img
                v-show="index == 7"
                src="../../assets/images/NewUser/pc-newuser-topicon8.png"
                alt="" />
              <img
                v-show="index == 8"
                src="../../assets/images/NewUser/pc-newuser-topicon9.png"
                alt="" />
              <img
                v-show="index == 9"
                src="../../assets/images/NewUser/pc-newuser-topicon10.png"
                alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="contentbox1" v-if="actypeindex === 2">
        <div style="display: flex">
          <div class="itembox" v-for="(item, i) in gameList" :key="i">
            <div class="titlebox">
              <img
                v-if="item.type_name === '盲盒开箱'"
                src="../../assets/images/NewUser/pc-newuser-djitembg.png"
                class="type"
                alt="" />
              <img
                v-else
                src="../../assets/images/NewUser/pc-newuser-spitembg.png"
                class="type"
                alt="" />
            </div>
            <div class="goodsbox">
              <img style="width: 90%" :src="item.skins_img" alt="" />
            </div>
            <div class="infobox">
              <div class="price"></div>
              <div class="infoname">{{ item.skins_name }}</div>
            </div>
            <div class="line"></div>
            <div class="priceinfobox">
              <div v-if="item.box_bean != ''">
                <div class="time">{{ item.created_at }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPerformanceApi, getNewlyHighLightApi } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      typedata: [
        {
          id: 2,
          title: "高光时刻",
        },
        {
          id: 1,
          title: "最近获得",
        },
      ],
      actypeindex: 2,
      performanceList: [],
      gameList: [
        {
          type_name: "盲盒开箱",
          skins_img: "",
          skins_price: "",
          skins_name: "",
          box_bean: "",
          created_at: "",
        },
        // {
        //   type_name: '盲盒对战',
        //   skins_img: '',
        //   skins_price: '',
        //   skins_name: '',
        //   box_bean: '',
        //   created_at: ''
        // },
        // {
        //   type_name: '幸运饰品',
        //   skins_img: '',
        //   skins_price: '',
        //   skins_name: '',
        //   box_bean: '',
        //   created_at: ''
        // },
      ],
    };
  },
  created() {
    this.getPerformance();
    this.getNewlyHighLight();
  },
  computed: {
    ...mapState(["popupHighLightId"]),
  },
  methods: {
    typechange(v) {
      this.actypeindex = v;
      if (v == 2) {
        this.getNewlyHighLight();
      }
    },
    // 近期表现
    getPerformance() {
      getPerformanceApi({ uid: this.popupHighLightId }).then((res) => {
        this.performanceList = res.data.data;
      });
    },
    // 盲盒游戏
    getNewlyHighLight() {
      getNewlyHighLightApi({ uid: this.popupHighLightId }).then((res) => {
        this.gameList = this.gameList.map((item, index) => {
          let retItem = item;
          res.data.data.forEach((element) => {
            if (element.type_name === item.type_name) {
              retItem = element;
            }
          });
          return retItem;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .typebox {
    width: 100%;
    display: flex;
    .typeitem {
      width: 1.2rem;
      padding: 0.32rem 0 0.08rem 0;
      font-size: 0.18rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      line-height: 0.21rem;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .spcontent {
    width: 100%;
    .contentbox1 {
      width: 100%;
      height: 9.5rem;
      background: #08080f;
      border: 0.02rem solid #2f2f5e;
      .listcenter {
        width: 10rem;
        margin: 0.29rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .listitem {
          width: 4.94rem;
          height: 1.64rem;
          background: #101022;
          border: 0.02rem solid #201e43;
          margin-bottom: 0.18rem;
          border-radius: 0.04rem;
          display: flex;
          justify-content: space-between;
          position: relative;

          .lbox {
            width: 40%;
            margin: 0.12rem 0 0.12rem 0.35rem;
            .timebox {
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #606085;
            }
            .imgbox {
              width: 1.4rem;
              height: 1.05rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: url("../../assets/images/NewUser/pc-newuser-itemboxbg.png")
                no-repeat;
              background-size: 100% 100%;
            }
          }
          .rbox {
            width: 45%;
            margin-top: 0.45rem;
            z-index: 5;
            .boxname {
              font-size: 0.18rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #516bf3;
              margin-bottom: 0.1rem;
            }
            .titlename {
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: 0.18rem;
            }
            .pricebox {
              display: flex;
              align-items: center;
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #efa900;
              img {
                width: 0.17rem;
                margin-right: 0.12rem;
              }
            }
          }
          .iconbox {
            position: absolute;
            top: 0;
            right: 0;
            img {
              width: 1.18rem;
              height: 1.1rem;
              z-index: 2;
            }
          }
        }
      }

      .itembox {
        width: 3.22rem;
        height: 8.9rem;
        background: #111121;
        border-radius: 0.04rem;
        margin: 0.3rem 0.15rem;
        .titlebox {
          width: 100%;
          height: 0.6rem;
          margin-top: 0.57rem;
          display: flex;
          justify-content: end;
          background: #141428;
          img {
            width: 2.38rem;
            height: 0.56rem;
            margin-right: 0.25rem;
          }
        }
        .goodsbox {
          width: 2.24rem;
          height: 2.41rem;
          background: url("../../assets/images/NewUser/pc-newuser-goodsimgbg.png")
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.93rem auto 0.37rem;
        }
        .infobox {
          height: 0.9rem;
          text-align: center;
          .infoname {
            margin-top: 0.27rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.24rem;
            color: #b1b1da;
          }
        }
        .line {
          width: 2.46rem;
          height: 0px;
          border: 0.01rem solid #535373;
          margin: 0 auto;
        }
        .priceinfobox {
          width: 100%;
          height: 3.1rem;
          background: linear-gradient(
            180deg,
            rgba(20, 20, 40, 0) 0%,
            #1b1b37 100%
          );
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.2rem;
          color: #b1b1da;
          .box-price {
            width: 100%;
            margin-bottom: 0.34rem;
          }
          .time {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
